import React from 'react';

type LogoProps = {
  color?: string;
  height?: number;
  width?: number;
};

const Logo = (props: LogoProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      zoomAndPan="magnify"
      viewBox="0 0 112.5 74.999997"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
      {...props}
    >
      <defs>
        <g />
      </defs>
      <g fill="#121217" fillOpacity="1">
        <g transform="translate(0.595012, 58.407075)">
          <g>
            <path d="M 21.625 -7.640625 L 20.421875 0 L 3.171875 0 L 3.171875 -41.8125 L 12.65625 -41.8125 L 12.65625 -7.640625 Z M 21.625 -7.640625 " />
          </g>
        </g>
      </g>
      <g fill="#121217" fillOpacity="1">
        <g transform="translate(22.398083, 58.407075)">
          <g>
            <path d="M 27.890625 0 L 20.015625 0 L 18.75 -2.328125 C 18.28125 -1.890625 17.445312 -1.289062 16.25 -0.53125 C 15.050781 0.21875 13.578125 0.59375 11.828125 0.59375 C 10.316406 0.59375 8.875 0.347656 7.5 -0.140625 C 6.125 -0.640625 5.007812 -1.613281 4.15625 -3.0625 C 3.300781 -4.519531 2.875 -6.6875 2.875 -9.5625 L 2.875 -31.65625 L 12.359375 -31.65625 L 12.359375 -10.390625 C 12.359375 -9.203125 12.535156 -8.40625 12.890625 -8 C 13.253906 -7.601562 13.753906 -7.40625 14.390625 -7.40625 C 15.148438 -7.40625 15.890625 -7.570312 16.609375 -7.90625 C 17.328125 -8.25 17.921875 -8.5625 18.390625 -8.84375 L 18.390625 -31.65625 L 27.890625 -31.65625 Z M 27.890625 0 " />
          </g>
        </g>
      </g>
      <g fill="#121217" fillOpacity="1">
        <g transform="translate(53.459999, 58.407075)">
          <g>
            <path d="M 29.625 0 L 19.234375 0.59375 L 12.78125 -17.921875 L 17.796875 -31.65625 L 28.4375 -31.65625 L 21.796875 -18.21875 Z M 12.65625 0 L 3.171875 0 L 3.171875 -43.015625 L 12.65625 -43.015625 Z M 12.65625 0 " />
          </g>
        </g>
      </g>
      <g fill="#121217" fillOpacity="1">
        <g transform="translate(82.968815, 58.407075)">
          <g>
            <path d="M 25.453125 0 L 17.625 0 L 16.1875 -2.390625 C 16.03125 -2.191406 15.691406 -1.84375 15.171875 -1.34375 C 14.648438 -0.84375 13.984375 -0.394531 13.171875 0 C 12.359375 0.394531 11.375 0.59375 10.21875 0.59375 C 9.34375 0.59375 8.40625 0.484375 7.40625 0.265625 C 6.40625 0.046875 5.457031 -0.398438 4.5625 -1.078125 C 3.664062 -1.753906 2.929688 -2.769531 2.359375 -4.125 C 1.785156 -5.476562 1.5 -7.3125 1.5 -9.625 C 1.5 -12.007812 1.894531 -13.914062 2.6875 -15.34375 C 3.476562 -16.78125 4.484375 -17.847656 5.703125 -18.546875 C 6.921875 -19.242188 8.175781 -19.710938 9.46875 -19.953125 C 10.757812 -20.191406 11.90625 -20.3125 12.90625 -20.3125 L 16.015625 -20.3125 L 16.015625 -22.453125 C 16.015625 -23.335938 15.785156 -23.988281 15.328125 -24.40625 C 14.867188 -24.820312 13.976562 -25.03125 12.65625 -25.03125 C 11.90625 -25.03125 11.007812 -24.9375 9.96875 -24.75 C 8.9375 -24.570312 7.910156 -24.363281 6.890625 -24.125 C 5.878906 -23.894531 5.015625 -23.660156 4.296875 -23.421875 L 4.296875 -30.46875 C 5.140625 -30.738281 6.535156 -31.101562 8.484375 -31.5625 C 10.429688 -32.019531 12.519531 -32.25 14.75 -32.25 C 16.820312 -32.25 18.65625 -31.988281 20.25 -31.46875 C 21.84375 -30.957031 23.109375 -30.035156 24.046875 -28.703125 C 24.984375 -27.367188 25.453125 -25.425781 25.453125 -22.875 Z M 16.015625 -6.6875 L 16.015625 -15.296875 L 14.09375 -15.234375 C 12.863281 -15.191406 12.007812 -14.820312 11.53125 -14.125 C 11.050781 -13.425781 10.8125 -12.0625 10.8125 -10.03125 C 10.8125 -8.320312 11.03125 -7.175781 11.46875 -6.59375 C 11.90625 -6.019531 12.503906 -5.734375 13.265625 -5.734375 C 13.898438 -5.734375 14.484375 -5.875 15.015625 -6.15625 C 15.554688 -6.4375 15.890625 -6.613281 16.015625 -6.6875 Z M 16.015625 -6.6875 " />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Logo;
